import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-inter\",\"display\":\"swap\",\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Catamaran\",\"arguments\":[{\"variable\":\"--font-catamaran\",\"weight\":[\"400\",\"500\",\"700\"],\"display\":\"swap\",\"subsets\":[\"latin\"]}],\"variableName\":\"catamaran\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Roboto_Mono\",\"arguments\":[{\"variable\":\"--font-roboto-mono\",\"display\":\"swap\",\"subsets\":[\"latin\"]}],\"variableName\":\"roboto_mono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/src/assets/css/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/providers.tsx");
